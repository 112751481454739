import styled from "styled-components";

export const ContainerUniversities = styled.div`
  margin-top: 3rem;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 4rem;

  @media (max-width: 600px) {
    margin-top: 0;
    flex-direction: column;

    img {
      &:not(:last-child) {
        margin-bottom: 3rem;
      }
    }
  }

  @media (min-width: 601px) and (max-width: 889px) {
    margin-top: 0;
    flex-wrap: wrap;
    padding: 2rem;

    img {
      margin-right: 3rem;
      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }

  @media (min-width: 900px) and (max-width: 1199px) {
    margin-top: 3rem;
    flex-wrap: wrap;
    padding: 2rem;

    img {
      margin: 0 5rem !important;
    }
  }
`;

export const CallStudent = styled.div`
  text-align: center;
  width: 55%;
  display: flex;
  justify-content: center;
  margin: 4rem auto 2rem auto;
  background: #ffe103;
  padding: 1rem;
  border-radius: 45px;
  font-weight: 900;
  font-size: 25px;

  a {
    margin: 0;
    color: #2f3a8f;
    text-decoration: none;
  }

  @media (max-width: 600px) {
    width: initial;
    margin-left: 15px;
    margin-right: 15px;
  }

  @media (min-width: 601px) and (max-width: 889px) {
    width: initial;
    margin-left: 15px;
    margin-right: 15px;
  }

  @media (min-width: 900px) and (max-width: 1199px) {
    width: 60%;
  }
`;
