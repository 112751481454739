import React from "react";
import { ContentFooter } from "./style";
import FavoriteIcon from "@mui/icons-material/Favorite";

export default function FooterHome() {
  return (
    <ContentFooter>
      <span>2024 @LigandoIdeiasRedação - Todos os Direitos Reservados.</span>
      <a
        className="developed-by"
        href="https://www.linkedin.com/in/antonio-alberto-almeida-mendes/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Desenvolvido com <FavoriteIcon /> por Antonio Almeida
      </a>
    </ContentFooter>
  );
}
