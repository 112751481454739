import React from "react";
import { Container, Grid } from "@mui/material";
import { ContainerHome, ContentHome } from "./style";
import HeaderHome from "../../components/HeaderHome";
import LeftSideHome from "../../components/LeftSideHome";
import RightSideHome from "../../components/RightSideHome";
import FooterHome from "../../components/FooterHome";
import PlansHome from "../../components/PlansHome";
import WhyRegisterHome from "../../components/WhyRegisterHome";
import TestimonyHome from "../../components/TestimonyHome";
import UniversitiesHome from "../../components/UniversitiesHome";

export default function Home() {
  return (
    <ContainerHome>
      <HeaderHome />

      <ContentHome>
        <Container fixed>
          <Grid container rowSpacing={0} columnSpacing={10}>
            <Grid item xs={12} sm={12} md={6}>
              <LeftSideHome />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <RightSideHome />
            </Grid>

            <Grid item xs={12}>
              <PlansHome />
            </Grid>
          </Grid>
        </Container>

        <WhyRegisterHome />

        <Container fixed>
          <Grid container>
            <Grid item xs={12}>
              <TestimonyHome />
            </Grid>
          </Grid>
        </Container>

        <UniversitiesHome />
      </ContentHome>

      <FooterHome />
    </ContainerHome>
  );
}
