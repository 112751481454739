import { FormControl, FormHelperText, InputLabel, Select } from "@mui/material";
import React, { createRef } from "react";
import { Controller } from "react-hook-form";

export default function SelectInput({
  name,
  variant,
  rules,
  errors,
  label,
  children,
  control,
  defaultValue,
}) {
  const labelId = `${name}-label`;
  const ref = createRef();
  return (
    <FormControl
      variant={variant ? variant : "outlined"}
      fullWidth
      required={rules?.required ? rules.required : false}
    >
      <InputLabel ref={ref} error={errors ? true : false} id={labelId}>
        {label}
      </InputLabel>
      <Controller
        render={({ field }) => (
          <Select
            {...field}
            variant={variant ? variant : "outlined"}
            labelId={labelId}
            label={label}
            error={errors ? true : false}
          >
            {children}
          </Select>
        )}
        rules={rules}
        control={control}
        name={name}
        defaultValue={defaultValue ? defaultValue : ""}
      />
      {errors?.type === "required" && (
        <FormHelperText error>Campo obrigatório</FormHelperText>
      )}
    </FormControl>
  );
}
